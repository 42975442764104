<template>
  <div class="container">
    <template v-if="loadingDocument">
      <div class="row">
        <div class="col-12 py-4 d-flex justify-content-center">
          <ProgressSpinner />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="row">
        <div class="col-12 py-4">
          <div class="p-fluid container-fluid">
            <Button 
              class="p-button-raised p-button-rounded my-3 p-button-danger" 
              icon="pi pi-arrow-left"
              @click="goBack" 
            />
              
            <div style="display: flex; align-items: center; margin-bottom: 1.5rem;"  >
              <h3 style="margin-bottom: 0;" >EDIT DOCUMENT</h3>
              <Button 
                class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                icon="pi pi-question-circle" 
                @click="goToHeaderPage('documentEdit-header')" >
              </Button>
            </div>


            <!-- Information  -->
            <Divider align="left">
              <div class="inline-flex align-items-center">
                <span class="p-tag">INFORMATION</span>
                <Button 
                  class="p-button-raised p-button-rounded p-button-help mx-3" 
                  icon="pi pi-question-circle" 
                  @click="goToHeaderPage('documentEdit-section1')" />
              </div>
            </Divider>

            <div class="row" style="margin-left: 20px;">
              <div class="col-12 col-md-8">
                <div class="field my-3">
                  <label for="title">Title</label>
                  <InputText id="title" v-model.trim="editDocumentForm.title" @blur="v$.editDocumentForm.title.$touch"
                    required="true" autofocus :class="{ 'p-invalid': !editDocumentForm.title }" />
                  <small class="p-error" v-if="!editDocumentForm.title">Title is required.</small>
                </div>
              </div>
              <div class="col-12 col-md">
                <div class="field my-3" style="max-width: 400px">
                  <label for="title">Document Date</label>
                  <Calendar 
                    v-model="editDocumentForm.documentDate" 
                    dateFormat="dd/mm/yy" 
                  />
                </div>
              </div>
            </div>

            <div class="row" style="margin-left: 20px;">
              <!-- Source -->
              <div class="col-12 col-md">
                <div class="field my-3">
                  <label for="title">Document Source Name</label>
                  <!-- // sourceName amend to DocumentSourcename -->
                  <InputText v-model.trim="editDocumentForm.documentSourceName" />
                </div>
                <div class="field my-3">
                  <label for="title">Document Source Type</label>
                  <!-- // sourceId amend to DocumentSourceTypeId -->
                  <Dropdown 
                    v-model="editDocumentForm.documentSourceTypeId" 
                    :options="documentSources" 
                    optionLabel="name"
                    optionValue="documentSourceId" 
                    placeholder="Document Source" 
                  />
                </div>

              </div>
              <!-- Applications and Notes -->
              <div class="col-12 col-md">
                <!-- Hide Application feat usability 11-01-2024 -->

                <!-- <div class="field my-3">
                  <label for="title">Application</label>
                  <MultiSelect 
                    v-model="editDocumentForm.applications" 
                    :options="documentApplications"
                    optionLabel="name" 
                    optionValue="applicationTypeId" 
                    placeholder="Select Application" 
                    display="chip"
                    @change="validateApplicationOptions($event)" 
                    @show="validateShowOptions($event)"
                    :showToggleAll="false"
                  >
                    <template #option="slotProps">
                      <div :id="
                        'application-option-' +
                        slotProps.option.applicationTypeId
                      " class="p-multiselect-option hidden">
                        <span>{{ slotProps.option.name }}</span>
                      </div>
                    </template>
                  </MultiSelect>
                </div> -->
                
                <div class="field my-3">
                  <label for="notes">Notes</label>
                  <Textarea id="notes" v-model.trim="editDocumentForm.notes" required="true" rows="3" cols="30" />
                </div>
                
              </div>
              <!-- Origin Sources -->
              <div class="col-12 col-md card bg-light">
                <div class="field my-3">
                  <label for="title">Origin</label>
                  <!-- toDo look response and test  -->
                  <!-- v-model.trim="editDocumentForm.origin.originName" -->
                  <div v-if="editDocumentForm.origin">
                    <InputText
                      v-model.trim="editDocumentForm.origin.originName"
                      disabled
                    />
                  </div>
                  <div v-else>
                    <InputText
                      disabled
                    />
                  </div>
                </div>
                <div class="field my-3">
                  <label for="title">Origin Document Id</label>
                  <!-- ToDo Ammend to originDocumentId -->
                  <InputText
                    v-model.trim="editDocumentForm.originDocumentId"
                    disabled
                  />
                </div>
                <div class="field my-3">
                  <label for="title">Origin Company Id</label>
                  <InputText
                    v-model.trim="editDocumentForm.originCompanyId"
                    disabled
                  />
                </div>
              </div>
            </div>

            <!-- Contents -->
            <Divider align="left">
              <div class="inline-flex align-items-center">
                <span class="p-tag">CONTENTS</span>
                <Button 
                  class="p-button-raised p-button-rounded p-button-help mx-3" 
                  icon="pi pi-question-circle" 
                  @click="goToHeaderPage('documentEdit-section2')" 
                />
              </div>
            </Divider>

            <div style="margin-left: 25px; margin-top: 1rem; display: flex; justify-content: space-between; width: 99%;">
              <!-- <div class="col-12 col-md-6 card"> -->
              <div class="card " style="width: 50%; padding: 0 2.5% 0 2.5% ;">
                <div class="field my-3">
                  <label for="websiteUrl">Website url </label>
                  <a :href="editDocumentForm.websiteUrl" target="_blank" rel="noreferrer" style="margin-left: 0.5rem;">
                    Check Url
                  </a>
                  <InputText id="websiteUrl" v-model.trim="editDocumentForm.websiteUrl" autofocus />
                </div>
              </div>

              <div class="card "  style="width: 45%; padding: 0 2.5% 0 2.5% ;">
                <div class="field my-3">
                  <label for="websiteUrl">Files</label>

                  <!-- {{ document.filePublicUrl }} -->
                  <!-- {{ files  }}
                  {{editDocumentForm}}
                  {{editDocumentForm.filePublicUrl}} -->
                  <div v-if="editDocumentForm.filePublicUrl" style="display: flex; gap: 0.5rem; align-items: center;"  >
                    <!-- viewFile  -->
                    <div v-if="files.length === 0">
                      <Button 
                        icon="pi pi-file-pdf"
                        label="View Pdf"
                        style="width: 8rem;"
                        @click="redirectViewPdf(document.filePublicUrl)"
                      />
                      <!-- <a
                        :href="document.filePublicUrl"
                        target="_blank"
                        rel="Document File"
                        class="document-File"
                      >
                        <i class="pi pi-file-pdf" style="font-size: 1rem"></i>
                        <label for="file">View PDF</label>  
                      </a> -->
                    </div>
                    <!-- change File  -->
                    <FileUpload 
                        name="files" 
                        mode="basic" 
                        :customUpload="false" 
                        @uploader="onUpload"
                        uploadLabel="Use these files" 
                        @select="fileChange" 
                        :multiple="false" 
                        :maxFileSize="10000000"
                        accept="application/pdf"
                        chooseLabel="Change File"
                        class="p-button-success"
                      >
                        <template #empty>
                          <p>Drag and drop files to here to upload.</p>
                        </template>
                    </FileUpload>

                    <!-- Delete File   -->
                    <Button 
                      icon="pi pi-trash" 
                      class=" p-button-danger"
                      label="Delete"
                      style="width: 8rem;"
                      @click="removeFileFromDocuments()"
                    />
                  </div>
                  <div v-else>
                    <FileUpload 
                      name="files" 
                      mode="basic" 
                      :customUpload="false" 
                      @uploader="onUpload"
                      uploadLabel="Use these files" 
                      @select="fileChange" 
                      :multiple="false" 
                      :maxFileSize="10000000"
                      accept="application/pdf"
                    >
                      <template #empty>
                        <p>Drag and drop files to here to upload.</p>
                      </template>
                    </FileUpload>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-left: 20px;">
              <div class="col-12">
                <div class="field my-3">
                  <Panel :toggleable="true" :collapsed="isCollapsed">
                    <!-- <Panel header="Text" :toggleable="true" :collapsed="true"> -->
                    <template #header>
                      <div style="
                        padding: 0.8% 0.8% 0.8% 2.6%; 
                        width: 100%; 
                        height: 100%;" @click="isCollapsed = !isCollapsed">
                        <strong style="margin: 0;"> Text</strong>
                      </div>
                    </template>
                    <QuillEditor theme="snow" toolbar="full" v-model:content="editDocumentForm.text" contentType="html"
                      :style="{ 'min-height': '200px' }" />
                  </Panel>
                </div>
              </div>
            </div>

            <div class="row" style="margin-left: 20px;">
                <div class="field my-3">
                  <label for="notes">Contract summary for "Contracts per Company Overview"</label>
                  <Textarea
                    id="contract"
                    v-model.trim="editDocumentForm.contract"
                    rows="5"
                    cols="30"
                  />
                </div>
            </div>

            <!-- Relationships -->
            <Divider align="left">
              <div class="inline-flex align-items-center">
                <span class="p-tag">RELATIONSHIPS</span>
                <Button 
                  class="p-button-raised p-button-rounded p-button-help mx-3" 
                  icon="pi pi-question-circle" 
                  @click="goToHeaderPage('documentEdit-section3')" />
              </div>
            </Divider>

            <div class="row" style="margin-left: 20px; margin-bottom: 5rem;">
              <!-- Topics -->
              <div class="col-12 col-sm-12 col-md-4">
                <div class="my-3">
                  <Button icon="pi pi-plus" label="TOPICS" class="p-button-success" @click="addTopic" />
                  <template v-for="(topic, index) in editDocumentForm.documentTopics" :key="topic.topicId">
                    <div class="d-flex my-3 ms-3">
                      <Button 
                        icon="pi pi-minus" 
                        class="p-button-danger p-button-rounded me-3"
                        @click="removeTopic(topic.topicId, index)" 
                      />
                      <Dropdown 
                        v-model="topic.topicId" 
                        :options="topics" 
                        optionLabel="name" 
                        optionValue="topicId"
                        placeholder="Select topic" 
                        style="width: 70%" 
                        :filter="true"
                      />
                    </div>
                  </template>
                </div>
              </div>
              <!-- Country -->
              <div class="col-12 col-sm-12 col-md-4">
                <div class="my-3">
                  <Button 
                    icon="pi pi-plus" 
                    label="COUNTRY" 
                    class="p-button-warning" 
                    @click="addCountry" 
                  />
                  <template v-for="country in editDocumentForm.countries" :key="country.countryId">
                    <div class="d-flex my-3 ms-3">
                      <Button 
                        icon="pi pi-minus" 
                        class="p-button-danger p-button-rounded me-3"
                        @click="removeCountry(country.countryId)" 
                      />
                      <Dropdown 
                        v-model="country.countryId" 
                        :options="countries" 
                        optionLabel="name"
                        optionValue="countryId" 
                        :filter="true" 
                        placeholder="Select country" 
                        style="width: 70%" 
                      />
                    </div>
                  </template>
                </div>
              </div>
              <!-- Company -->
              <div class="col-12 col-sm-12 col-md-4">
                <div class="my-3">
                  <Button 
                    icon="pi pi-plus" 
                    label="COMPANY" 
                    class="p-button-help" 
                    @click="addCompany" 
                  />
                  <template v-for="company in editDocumentForm.companies" :key="company.companyId">
                    <div class="d-flex my-3 ms-3">
                      <div v-if="!company.companyId">
                        <DocumentCreateCompany 
                          :countries="countries" 
                          :company="company"
                          @addLastCompany="addLastCompany" 
                        />
                      </div>
                      <Button icon="pi pi-minus" class="p-button-danger p-button-rounded me-3"
                        @click="removeCompany(company.companyId)" />
                      <Dropdown 
                        v-model="company.companyId" 
                        :options="companies" 
                        optionLabel="name"
                        optionValue="companyId" 
                        placeholder="Select Company" 
                        style="width: 70%" 
                        :filter="true"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <!-- Topics  -->
            <template v-if="editDocumentForm.documentTopics && editDocumentForm.documentTopics.length > 0">
              <Divider align="left" >
                <div class="inline-flex align-items-center">
                  <span class="p-tag">TOPICS</span>
                  <Button 
                    class="p-button-raised p-button-rounded p-button-help mx-3" 
                    icon="pi pi-question-circle" 
                    @click="goToHeaderPage('documentEdit-section4')" 
                  />
                </div>
              </Divider>

              <div class="row" style="margin-left: 20px; margin-top: 20px;">
                <div class="col-12 card" style="padding: 10px 20px; margin-bottom: 20px;"
                  v-for="(topic, index) in editDocumentForm.documentTopics" :key="topic.topicId">
                  <DocumentTopic 
                    :topic="topic" 
                    :topics="topics" 
                    :isEditDocumentTopic="true" 
                    @updateTopic="updateTopic"
                    :index="index" 
                    @saveDocument ="editDocumentWithRedirectProgram"
                    @redirectProgram="redirectProgram"
                    @syncCountryCompanyWithProgram = "syncCountryCompanyWithProgram"
                  />
                  <div style="margin-top: 1rem;">
                    <!-- :marketValues="editDocumentForm.documentTopics[index].documentTopicMarketValues"  -->
                    <Carousel 
                      @getMarketValue="getMarketValue"
                      :marketValues="editDocumentForm.documentTopics[index].documentTopicMarketValues" 
                      :index="index" 
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 p-4">
          <div class="d-flex justify-content-end px-3" style="gap:1rem;">
              <!-- <Button 
                label="Save" 
                icon="pi pi-check" 
                class="p-button-success ml-3" 
                :loading="editDocumentIsLoading"
                :disabled="
                  this.v$.editDocumentForm.$invalid || editDocumentIsLoading
                " @click="editDocumentPage()" /> -->

              <Button
                class="p-button"
                :loading="editDocumentIsLoading"
                :disabled="this.v$.editDocumentForm.$invalid || editDocumentIsLoading" 
                @click="editDocumentPage()"
              >
                <label style="margin-right: 0.5rem;" >Save</label>
                <font-awesome-icon icon="fa-regular fa-floppy-disk" />
              </Button>

              <Button
                class="p-button-success " 
                :loading="editDocumentIsLoading"
                :disabled="this.v$.editDocumentForm.$invalid || editDocumentIsLoading" 
                @click="editDocumentPageGoBack()" 
              >
                <label style="margin-right: 0.5rem;">Save and Go Back</label>
                <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
              </Button>

          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import formatToGlobalDate from '@/shared/helpers/date.helper';
import { createFormData, createFormData2 } from '@/shared/helpers/formData.helper';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
// import MultiSelect from 'primevue/multiselect';
import Divider from 'primevue/divider';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { QuillEditor } from '@vueup/vue-quill';
import Calendar from 'primevue/calendar';
import { useToast } from 'vue-toastification';
import DocumentTopic from '../components/DocumentTopic.vue';
import DocumentCreateCompany from '../components/DocumentCreateCompany.vue';
import ProgressSpinner from 'primevue/progressspinner';
import Carousel from '../components/Carousel.vue';
import redirectHelperPage from '../../../../../../shared/helpers/redirectToHelperPage';

const toast = useToast();

export default {
  name: 'EditDocument',
  components: {
    Button,
    InputText,
    QuillEditor,
    Dropdown,
    Textarea,
    FileUpload,
    // MultiSelect,
    Divider,
    Panel,
    Calendar,
    DocumentTopic,
    DocumentCreateCompany,
    Carousel,
    ProgressSpinner,
    
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loadingDocument: true,
      editDocumentIsLoading: false,
      editDocumentDialog: false,
      documentId: null,
      files: [],
      editDocumentForm: {
        documentSourceTypeId: null, // sourceId amend to DocumentSourceTypeId
        documentSourceName: null, // sourceName amend to DocumentSourcename
        applications: null,
        // companyId: null,
        file: null,
        notes: null,
        text: null,
        title: null,
        websiteUrl: null,
        documentDate: null,
        originDocumentId: 0,  // originalSourceId amend to OriginDocumentId
      
        // add new Columns
        // originId: null, //on originTable 
        originCompanyId : null, 
      },
      newOriginForm: {
        originName: null,
        originId: null,
      },
      uniqueOptions: [],
      documentTopics: [],
      documentCountries: [],
      documentCompanies: [],
      isCollapsed: true,
    };
  },
  validations() {
    return {
      editDocumentForm: {
        title: { required },
        // notes: { required },
        // text: { required },
        // websiteUrl: { required },
        // documentSourceTypeId: { required },  // sourceId amend to DocumentSourceTypeId
        // companyId: { required },
        // applications: { required },
      },
    };
  },
  async created() {
    await this.getTopics();
  },
  async mounted() {
    await this.getDocumentById(this.$route.params.documentId);
    await this.getDocumentApplications();
    await this.getDocumentSources();
    await this.getCompanies();
    await this.getCountries();
    // await this.getTopics();
  },
  computed: {
    document() {
      return this.$store.getters.obtainDocument;
    },
    selectedTopic() {
      return this.$store.getters.obtainSelectedTopic;
    },
    documentApplications() {
      const applications = this.$store.getters.obtainDocumentApplications;
      applications.forEach((application) => {
        if (
          // application.name == 'Production' ||
          // application.name == 'Requirements'
          application.name == 'Forecast - Production' ||
          application.name == 'Forecast - Requirement'
        ) {
          this.uniqueOptions.push(application);
        }
      });
      return applications;
    },
    documentSources() {
      let documentSourcesSort = this.$store.getters.obtainDocumentSources
      documentSourcesSort.sort(function (a,b){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      }); 
      return documentSourcesSort;
    },
    companies() {
      let companiesOrder = this.$store.getters.obtainCompanies 

      companiesOrder.sort(function (a,b){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      });

      return companiesOrder;
    },
    topics() {
      let topicsOrder = this.$store.getters.obtainTopics 
      topicsOrder.sort(function (a,b){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      });

      return topicsOrder;
    },
    countries() {
      const x = this.$store.getters.obtainCountries
      return x.sort((a,b) => a.name.localeCompare(b.name))
    },
    computedOpts() {
      const res = this.topics.map(topic => {
        const diff = this.topics.filter(({ topicId: topicId1 }) => !this.editDocumentForm.documentTopics.some(({ topicId: topicId2 }) => topicId2 === topicId1));
        const disabled = !diff.includes(t => t.topicId == topic.topicId);
        return {
          ...topic,
          disabled: typeof this.topic.disabled !== 'undefined' ? disabled : undefined
        }
      })
      return res;
    }
  },
  methods: {

    goBack() {
      this.$router.go(-1);
    },
    goToHeaderPage(section){
      redirectHelperPage(section)
    },

    async getDocumentById(documentId) {
      try {
        this.loadingDocument = true;
        await this.$store.dispatch('obtainDocumentsByDocumentId', documentId);
        this.loadingDocument = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingDocument = false;
      }
    },
    addTopic() {
      this.editDocumentForm.documentTopics.push({
        topicId: '',
      });
    },
    removeTopic(topicId, index) {
      this.editDocumentForm.documentTopics = this.editDocumentForm.documentTopics.filter(
        (t, i) => i != index
      );
    },
    addCountry() {
      this.editDocumentForm.countries.push({
        countryId: '',
      })
    },
    removeCountry(countryId) {
      this.editDocumentForm.countries = this.editDocumentForm.countries.filter(
        (country) => country.countryId != countryId
      );
    },
    addCountrySyncWithProgram(countryId){
      this.editDocumentForm.countries.pop();
      this.editDocumentForm.countries.push({"countryId": countryId})
    },

    addCompany() {
      this.editDocumentForm.companies.push({
        companyId: '',
      })
    },
    removeCompany(companyId) {
      this.editDocumentForm.companies = this.editDocumentForm.companies.filter(
        (company) => company.companyId != companyId
      )
    },
    addLastCompany() { //when created new Company Add last Created
      this.editDocumentForm.companies.pop();
      this.editDocumentForm.companies.push({ "companyId": this.companies[this.companies.length - 1].companyId })
      // console.log(this.documentCompanies);
    },
    addCompanySyncWithProgram(companyId){
      this.editDocumentForm.companies.pop();
      this.editDocumentForm.companies.push({ "companyId": companyId })
    },

    // IF PROGRAM CHANGE ADD COUNTRY COMPANY 
    syncCountryCompanyWithProgram(companyAndCountry){
      if(companyAndCountry.manufactured){
        this.addCompany(); 
        this.addCompanySyncWithProgram(companyAndCountry.manufactured)
      }
      // if(companyAndCountry.contryManufactured){
      //   const countryId = this.countries.find(country => country.name === companyAndCountry.contryManufactured)
      //   this.addCountry();
      //   this.addCountrySyncWithProgram(countryId.countryId);
      // }
      if(companyAndCountry.countryId){
        const countryId =  companyAndCountry.countryId
        this.addCountry();
        this.addCountrySyncWithProgram(countryId);
      }
    },

    validateShowOptions() {
      if (this.editDocumentForm.applications) {
        this.validateApplicationOptions({
          value: this.editDocumentForm.applications,
        });
      }
      if (this.editDocumentForm.applications) {
        this.validateApplicationOptions({
          value: this.editDocumentForm.applications,
        });
      }
    },
    validateApplicationOptions($event) {
      const selectedOptions = $event.value;
      if (selectedOptions.length == 0 || !selectedOptions.includes()) {
        const requirement = this.documentApplications.find(
          // (application) => application.name == 'Requirements'
          (application) => application.name == 'Forecast - Requirement'
        );
        const optionReq = document.querySelector(
          '#application-option-' + requirement.applicationTypeId
        );
        if (optionReq) {
          const li = optionReq.parentElement;
          li.style.display = 'flex';
        }
        const production = this.documentApplications.find(
          (application) => application.name == 'Forecast - Production'
        );
        const optionProd = document.querySelector(
          '#application-option-' + production.applicationTypeId
        );
        if (optionProd) {
          const li = optionProd.parentElement;
          li.style.display = 'flex';
        }
      }
      selectedOptions.forEach((selectedOption) => {
        if (
          selectedOption ==
          this.uniqueOptions.find((a) => a.name == 'Forecast - Production')
            .applicationTypeId
        ) {
          const requirement = this.uniqueOptions.find(
            // (a) => a.name == 'Requirements'
            (a) => a.name == 'Forecast - Requirement'
          );
          const option = document.querySelector(
            '#application-option-' + requirement.applicationTypeId
          );
          if (option) {
            const li = option.parentElement;
            li.style.display = 'none';
          }
        }
        if (
          selectedOption ==
          // this.uniqueOptions.find((a) => a.name == 'Requirements')
          this.uniqueOptions.find((a) => a.name == 'Forecast - Requirement')
            .applicationTypeId
        ) {
          const production = this.uniqueOptions.find(
            (a) => a.name == 'Forecast - Production'
          );
          const option = document.querySelector(
            '#application-option-' + production.applicationTypeId
          );
          if (option) {
            const li = option.parentElement;
            li.style.display = 'none';
          }
        }
      });
    },
    async getDocumentApplications() {
      try {
        this.loadingDocument = true;
        await this.$store.dispatch('obtainDocumentApplications');
        // toast.success('Document Applications obtained successfully.');
        this.loadingDocument = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingDocument = false;
      }
    },
    async getDocumentSources() {
      try {
        this.loadingDocument = true;
        await this.$store.dispatch('obtainDocumentSources');
        // toast.success('Document Sources obtained successfully.');
        this.loadingDocument = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingDocument = false;
      }
    },
    async getCompanies() {
      try {
        this.loadingDocument = true;
        await this.$store.dispatch('obtainCompanies');
        // toast.success('Companies obtained successfully.');
        this.loadingDocument = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingDocument = false;
      }
    },
    async getTopics() {
      try {
        this.loadingDocument = true;
        await this.$store.dispatch('obtainTopics');
        this.loadingDocument = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingDocument = false;
      }
    },
    async getCountries() {
      try {
        this.loadingDocument = true;
        await this.$store.dispatch('obtainCountries');
        this.loadingDocument = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingDocument = false;
      }
    },
    formatDate(date) {
      if (!date) return '';
      return formatToGlobalDate(date);
    },

    async editDocumentPage(){
      await this.editDocument();
      toast.success('Document Saved!');
    },

    async editDocumentPageGoBack(){
      await this.editDocument();
      this.goBack();
    },

    async editDocumentWithRedirectProgram(redirectDocumentProgramTopicId){
      await this.editDocument();
      this.$router.push(`../../program/overview/${redirectDocumentProgramTopicId}`)
      // window.open(`../../program/overview/${this.redirectDocumentProgramTopicId}`, '_blank')
    },

    redirectProgram(redirectDocumentProgramTopicId){
      this.$router.push(`../../program/overview/${redirectDocumentProgramTopicId}`)
    },

    async editDocument() {
      try {
        if (!this.v$.editDocumentForm.$invalid) {
          this.editDocumentIsLoading = true;
          let documentForm = new FormData();

          documentForm.append('file', this.files.length > 0 ? this.files[0] : null);
          for (let key of Object.keys(this.editDocumentForm)) {
            if (this.editDocumentForm[key]) {
              documentForm.append(key, this.editDocumentForm[key]);
            }
          }
          documentForm.set('applicationsString', this.editDocumentForm.applications);
          documentForm.set('documentDate', `${this.editDocumentForm.documentDate.getFullYear()}-${this.editDocumentForm.documentDate.getMonth()+1}-${this.editDocumentForm.documentDate.getDate()}T00:00:00`);
          // documentForm.set('documentDate', formatToGlobalDate(this.editDocumentForm.documentDate));
          documentForm.delete('applications');

          let documentTopics = this.editDocumentForm.documentTopics.map(t => {
            // Unify segmentationItems array
            // const segmentationItems = []
            // t.segmentationItems.forEach(array => {
            //   segmentationItems.push(...array);
            // })
            // console.log(t)
            // if (t.regionCountries) {
            //   return {
            //     topicId: t.topicId,
            //     // regionCountries: t.regionCountries,
            //     // program: t.program,
            //     // segmentationItems: t.segmentationItems,
            //     documentTopicId: t.documentTopicId,
            //     documentProgramId: t.documentProgramId,
            //     documentTopicMarketValues: t.documentTopicMarketValues,
            //   }
            // }
            // else {
            //   return {
            //     topicId: t.topicId,
            //     // program: t.program,
            //     // segmentationItems: t.segmentationItems,
            //     documentTopicId: t.documentTopicId,
            //     documentProgramId: t.documentProgramId,
            //     documentTopicMarketValues: t.documentTopicMarketValues,
            //   }
            // }

            if (t.documentTopicId) {
              // REMOVE THIS ON PROD 
              // t.documentTopicMarketValues.map(documentTopicMarketValue => 
              //     documentTopicMarketValue.marketValue =  documentTopicMarketValue.marketValue.toString().replace('.',',')  
              // )
              if(t.documentProgramId){ //validate if documentProgramId exist

                return {
                  topicId: t.topicId,
                  // program: t.program,
                  // segmentationItems: t.segmentationItems,
                  documentTopicId: t.documentTopicId,
                  documentProgramId: t.documentProgramId,
                  documentTopicMarketValues: t.documentTopicMarketValues,
                }
              }
              else{
                return {
                  topicId: t.topicId,
                  // program: t.program,
                  // segmentationItems: t.segmentationItems,
                  documentTopicId: t.documentTopicId,
                  // documentProgramId: t.documentProgramId,
                  documentTopicMarketValues: t.documentTopicMarketValues,
                }
              }
            }
            else {
              if(t.documentProgramId){ //validate if documentProgramId exist
                return {
                  topicId: t.topicId,
                  // program: t.program,
                  // segmentationItems: t.segmentationItems,
                  // documentTopicId: t.documentTopicId,
                  documentProgramId: t.documentProgramId,
                  documentTopicMarketValues: t.documentTopicMarketValues,
                }
              }
              else{
                return {
                  topicId: t.topicId,
                  // program: t.program,
                  // segmentationItems: t.segmentationItems,
                  // documentTopicId: t.documentTopicId,
                  // documentProgramId: t.documentProgramId,
                  documentTopicMarketValues: t.documentTopicMarketValues,
                }
              }

              
            }
          });
          documentForm.delete('documentTopics');
          documentForm.delete('countries');
          documentForm.delete('companies');
          documentForm.delete('marketValues');
          documentForm.delete('topics');


          // check for delete origin object 

          // console.log(documentTopics);

          // createFormData(documentForm, 'topics', documentTopics);
          createFormData(documentForm, 'documentTopics', documentTopics);
          createFormData2(documentForm, 'countryIds', this.editDocumentForm.countries)
          createFormData2(documentForm, 'companyIds', this.editDocumentForm.companies);
          // this.editDocumentForm.marketValues.map(item => item.segmentationItemIds = this.editDocumentForm.documentTopics[0].segmentationItems)
          // createFormData(documentForm, 'marketValues', this.editDocumentForm.marketValues);
          // for (var pair of documentForm.entries()) {
          //   console.log(pair[0]+ ', ' + pair[1]); 
          // }
          // console.log([...documentForm]);
          await this.$store.dispatch('modifyDocument', documentForm);
          this.editDocumentIsLoading = false;
          // toast.success('Successfully updated.');
      
        } else {
          toast.error('Wrong topic data.');
        }
      } catch (error) {
        this.editDocumentIsLoading = false;
        toast.error(error.message);
      }
    },
    
    onUpload() {
      toast.success('Successfully selected.');
    },
    fileChange($event) {
      this.files = $event.files;
    },
    redirectViewPdf(filePublicUrl){
      console.log('open', filePublicUrl);
      window.open(`${filePublicUrl}`, '_blank')
    },
    removeFileFromDocuments(){
      this.file = null;
      this.editDocumentForm.filePublicUrl = null;
    },
    // for update segmentation
    updateTopic({ documentTopic, index }) {
      // const topicIndex = this.editDocumentForm.documentTopics.findIndex(
      //   (t) => t.topicId == documentTopic.topicId
      // );
      // console.log(documentTopic);
      // console.log(index);
      this.editDocumentForm.documentTopics[index] = {
        ...this.editDocumentForm.documentTopics[index],
        ...documentTopic,
      };
      // console.log('document',this.editDocumentForm.documentTopics);
    },
    // for carousel 
    getMarketValue({ object, index }) {
      let b = object.flat().map(item => ({
        ...item,
      }))

      this.editDocumentForm.documentTopics[index] = {
        ...this.editDocumentForm.documentTopics[index],
        "documentTopicMarketValues": b
      }
      // console.log(this.editDocumentForm.documentTopics[index]);
    },
  },
  watch: {
    // check this important 
    document(newVal) {
      if (newVal) {
        newVal.documentDate = new Date(newVal.documentDate);
        this.editDocumentForm = Object.assign({}, newVal);
        // console.log(this.editDocumentForm);
        let companyArray = []
        newVal.documentCompanies.map(company => companyArray.push(company.company))
        this.editDocumentForm.companies = companyArray;

        let countryArray = []
        newVal.documentCountries.map(country => countryArray.push(country.country))
        this.editDocumentForm.countries = countryArray

        // originData uncheck Commend afterBackend 
        // this.newOriginForm = newVal.origin

        delete this.editDocumentForm.documentCompanies
        delete this.editDocumentForm.documentCountries
        this.isCollapsed = false
        // console.log(this.editDocumentForm);
      }
    },
    // 'editDocumentForm.documentTopics': {
    //   handler(newVal) {
    //     try {
    //       if (newVal) {
    //         const newTopicId = newVal[newVal.length - 1].topicId;
    //         const prevArray = newVal.slice(0, newVal.length - 1)
    //         const topicExists = prevArray.findIndex(t => t.topicId == newTopicId) >= 0;
    //         if (topicExists) {
    //           this.editDocumentForm.documentTopics.pop();
    //         }
    //       }
    //     } catch {
    //       null
    //     }
    //   },
    //   deep: true,
    // },
    'editDocumentForm.countries': {
      handler(newVal) {
        try {
          if (newVal) {
            const newCountryId = newVal[newVal.length - 1].countryId;
            const prevArray = newVal.slice(0, newVal.length - 1)
            const countryExists = prevArray.findIndex(country => country.countryId == newCountryId) >= 0;
            if (countryExists) {
              this.editDocumentForm.countries.pop();
            }
          }
        }
        catch {
          null
        }
      },
      deep: true,
    },
    'editDocumentForm.companies': {
      handler(newVal) {
        try {
          if (newVal) {
            const newCompanyId = newVal[newVal.length - 1].companyId;
            const prevArray = newVal.slice(0, newVal.length - 1)
            const companyExists = prevArray.findIndex(company => company.companyId == newCompanyId) >= 0;
            if (companyExists) {
              this.editDocumentForm.companies.pop();
            }
          }
        } catch (error) {
          null
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.p-panel-header {
  padding: 0 !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {

  padding: 0.2rem 0.5rem !important;
}

.p-datepicker-month {
    background: #673AB7;
    color: white !important;
    /* text-decoration: underline; */
}
.p-datepicker-month:hover{
    background: blue;
}
.p-datepicker-year {
    background: #673AB7;
    color: white !important;
    /* text-decoration: underline; */
}
.p-datepicker-year:hover {
    background: blue;
}

.document-File{
  display: flex; 
  gap: 0.5rem; 
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: white;
  background-color: #673AB7;
  padding: 5%;
  width: 7rem;
  height: 3rem;
  border-radius: 0.2rem;
  cursor: pointer;
}

.document-File:hover{
  color: red;
}
</style>